import { ReactNode, useCallback, useContext, useId } from 'react';
import { FormattedMessage } from 'react-intl';
import { AutoAwesome, OpenInNew } from '@mui/icons-material';
import { Button, Chip, FormControlLabel, Popover, Stack, Switch, Typography } from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { bindPopover, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';

import { Instance as CompanyService } from '@/legacy/services/company';
import { Instance as UserService } from '@/legacy/services/user';
import UserContext from '@/providers/UserContext';
import readJsonFromLocalStorage from '@/utils/readJsonFromLocalStorage';
import writeJsonToLocalStorage from '@/utils/writeJsonToLocalStorage';

import useFeatureFlag, { UseFeatureFlagOptions } from '../hooks/useFeatureFlag';
import FeatureFlag from '../types/FeatureFlag';

export type UseFeatureFlagOptInOptions = {
  featureFlag: FeatureFlag;
  featureFlagKey?: UseFeatureFlagOptions['key'];
  featureFlagKeyType?: UseFeatureFlagOptions['keyType'];
};

export const useFeatureFlagOptIn = ({
  featureFlag,
  featureFlagKey = 'anonymous',
  featureFlagKeyType = undefined,
}: UseFeatureFlagOptInOptions) => {
  const { userId } = useContext(UserContext);
  const queryClient = useQueryClient();
  const { isFeatureEnabled: isFeatureAvailable, isLoading: isFeatureAvailableIsLoading } =
    useFeatureFlag(featureFlag, {
      key: featureFlagKey,
      keyType: featureFlagKeyType,
    });
  const { data: isFeatureOptedIn, isLoading: isFeatureOptedInIsLoading } = useQuery({
    queryKey: ['FeatureFlagOptIn', userId, featureFlag],
    queryFn: async () =>
      readJsonFromLocalStorage(`FeatureFlagOptIn/${featureFlag}/${userId}`, false),
  });
  const { mutate: setIsFeatureActive, isLoading: isMutating } = useMutation({
    mutationFn: async (value: boolean) => {
      writeJsonToLocalStorage(`FeatureFlagOptIn/${featureFlag}/${userId}`, value);
      return value;
    },
    onSuccess: (value: boolean) => {
      queryClient.setQueryData(['FeatureFlagOptIn', userId, featureFlag], value);
    },
  });
  const isLoading = isFeatureAvailableIsLoading || isFeatureOptedInIsLoading;
  return {
    isLoading,
    isFeatureAvailable,
    isFeatureActive: isFeatureAvailable && isFeatureOptedIn,
    setIsFeatureActive,
    isMutating,
  };
};

export type FeatureFlagOptInControlProps = UseFeatureFlagOptInOptions & {
  title: ReactNode;
  description: ReactNode;
  name: ReactNode;
  createFeedbackHref?: () => string;
};

export const FeatureFlagOptInControl = ({
  featureFlag,
  featureFlagKey,
  featureFlagKeyType,
  title,
  description,
  name,
  createFeedbackHref,
}: FeatureFlagOptInControlProps) => {
  const popupId = useId();
  const popupState = usePopupState({ variant: 'popover', popupId });
  const { isLoading, isFeatureAvailable, isFeatureActive, setIsFeatureActive, isMutating } =
    useFeatureFlagOptIn({
      featureFlag,
      featureFlagKey,
      featureFlagKeyType,
    });
  const toggleIsEnabled = useCallback(() => {
    setIsFeatureActive(!isFeatureActive);
  }, [isFeatureActive, setIsFeatureActive]);

  if (isLoading || !isFeatureAvailable) {
    return null;
  }

  const feedbackHref = createFeedbackHref();

  return (
    <>
      <Chip
        color="info"
        icon={<AutoAwesome fontSize="small" />}
        label={
          isFeatureActive ? (
            <FormattedMessage defaultMessage="Beta Active" />
          ) : (
            <FormattedMessage defaultMessage="Beta Available" />
          )
        }
        size="small"
        {...bindTrigger(popupState)}
      />
      <Popover
        {...bindPopover(popupState)}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        slotProps={{ root: { sx: { bgcolor: 'action.active' } } }}
      >
        <Stack p={2} pb={1} maxWidth={500} gap={2}>
          <Typography component="h1" variant="h4">
            {/* eslint-disable-next-line formatjs/no-literal-string-in-jsx */}
            {title}{' '}
            <Chip
              color="info"
              size="small"
              sx={{ verticalAlign: 'text-bottom' }}
              label={<FormattedMessage defaultMessage="Beta" />}
            />
          </Typography>
          <Typography component="div" variant="body1">
            {description}
          </Typography>
          <Stack direction="row" gap={2} alignItems="center" justifyContent="space-between">
            <FormControlLabel
              control={<Switch />}
              checked={isFeatureActive}
              onChange={toggleIsEnabled}
              disabled={isMutating}
              label={name}
            />
            <Button
              variant="text"
              disabled={!feedbackHref}
              href={feedbackHref}
              target="_blank"
              endIcon={<OpenInNew />}
            >
              <FormattedMessage defaultMessage="Send feedback" />
            </Button>
          </Stack>
        </Stack>
      </Popover>
    </>
  );
};

export type FeatureFlagOptInGateProps = UseFeatureFlagOptInOptions & {
  loadingChildren: ReactNode;
  enabledChildren: ReactNode;
  disabledChildren: ReactNode;
};

export const FeatureFlagOptInGate = ({
  featureFlag,
  featureFlagKey,
  featureFlagKeyType,
  loadingChildren,
  enabledChildren,
  disabledChildren,
}: FeatureFlagOptInGateProps) => {
  const { isFeatureActive, isLoading } = useFeatureFlagOptIn({
    featureFlag,
    featureFlagKey,
    featureFlagKeyType,
  });

  if (isLoading) {
    return <>{loadingChildren}</>;
  }

  if (isFeatureActive) {
    return <>{enabledChildren}</>;
  }

  return <>{disabledChildren}</>;
};

// --- Predefined controls so that they're consistent throughout the app

export const DataGridMigrationOptInControl = (
  <FeatureFlagOptInControl
    featureFlag={FeatureFlag.SPR19_2024_DATA_GRID_MIGRATION}
    title={<FormattedMessage defaultMessage="New & Improved Tables" />}
    description={
      <>
        <FormattedMessage defaultMessage="We've enhanced tables on Plooto with a refreshed look and new features. You can now adjust visual density, reorder columns via drag-and-drop, resize columns including auto-fitting to cell contents, show or hide columns including many new columns that have been added throughout the app, take any table into a fullscreen view, and export what you see to Excel or CSV. All your display preferences are saved for each table." />
        <br />
        <br />
        <FormattedMessage defaultMessage="More features to come. Availability will expand to more tables as the beta progresses. You can opt-out at any time." />
      </>
    }
    createFeedbackHref={() =>
      `https://plootoinc.typeform.com/to/EykoBz2K#company_id=${CompanyService.CompanyId()}&user_id=${UserService.UserId()}&company_name=${CompanyService.DisplayName()}&user_first_name=${UserService.DisplayFirstName()}&env=${import.meta.env.APP_ENVIRONMENT_LABEL}`
    }
    name={<FormattedMessage defaultMessage="Show new tables" />}
  />
);
